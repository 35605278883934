//获取区级联动接口
const areaLinkUrl = `/gateway/hc-property/space/getSpaceLinkList`;
//提交工单
const submitWorkInfo = `/gateway/blade-system/workInfoApp/submitWorkInfo`;
//获取我的上报
const getMySubmitWorkList = `/gateway/blade-system/workInfoApp/getMySubmitWorkList`;
//根据ID获取详情
const getWorkInfoById = `/gateway/blade-system/workInfoApp/getWorkInfoById`;
//根据ID获取详情
const saveOrUpdateWorkInfo = `/gateway/blade-system/workInfo/saveOrUpdateWorkInfo`;
//根据ID获取详情
const getServiceNum = `/gateway/blade-system/workInfo/getServiceNum`;

export {
  areaLinkUrl,
  submitWorkInfo,
  getMySubmitWorkList,
  getWorkInfoById,
  saveOrUpdateWorkInfo,
  getServiceNum,
};
